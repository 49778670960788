<template>
  <v-row>
    <loading :active.sync="loading"></loading>
    <v-col>
      <span
        style="
          font-family: 'IBM Plex Sans Thai';
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 40px;
        "
      >
        เพิ่มสาระความรู้
      </span>
    </v-col>
    <v-card class="pa-6" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">ชื่อ-นามสกุล (Full name)</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              hide-details
              v-model="full_name"
              placeholder="ชื่อ-นามสกุล (Full name)"
              dense
              outlined
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">Email</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              hide-details
              v-model="email"
              placeholder="Email"
              dense
              outlined
              :rules="rules.emailRules"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">Username</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              hide-details
              v-model="username"
              placeholder="Username"
              dense
              outlined
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" sm="12"
            ><v-btn
              v-model="isChangePassword"
              @click="isChangePassword = !isChangePassword"
              >Change Password</v-btn
            >
          </v-col>
        </v-row>
        <v-row v-if="isChangePassword">
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">Password</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              hide-details
              v-model="newPassword"
              placeholder="Password"
              dense
              outlined
              type="password"
              :rules="rules.required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">Confirm password</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              hide-details
              v-model="cmPassword"
              placeholder="Password"
              dense
              outlined
              type="password"
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" justify="end">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <v-btn @click="submit('q_1_1')" color="#2AB3A3">ยืนยัน</v-btn>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
import { VueEditor } from "vue2-editor";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
    VueEditor,
  },
  data() {
    return {
      dataChangePassword: {},
      dataNoPassword: {},
      username: "",
      password: "",
      full_name: "",
      email: "",
      user_type: "ADMIN",
      news_type: "ข่าวประชาสัมพันธ์",
      news_title: "",
      content: "",
      keyImg: "",
      activeFlag: true,

      editorOption: {
        placeholder: "ระบุข้อมูล",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        tel: [
          (v) =>
            (v.length <= 10 && v.length >= 9) ||
            "Phone Number must be 9-10 charactors only.",
          (v) => !!v || "Please enter your phone number.",
        ],
      },
      checkValidate: true,
      lazy: false,
      loading: false,
      loading: false,
      userLocalstorage: [],
    };
  },
  watch: {
    productId(val) {
      if (val) {
        console.log("productId", val);
      }
    },
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.userLocalstorage = JSON.parse(
      Decode.decode(localStorage.getItem("userData"))
    );
    this.getOne();
  },
  methods: {
    async getOne() {
      this.loading = true;
      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("gscmAdmin"))
      );
      console.log("this.userData", this.userData);
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users/${this.userLocalstorage.id}`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getNews", response.data.data);
      this.full_name = response.data.data.full_name;
      this.email = response.data.data.email;
      this.username = response.data.data.username;
      // this.password = response.data.data.password;
      this.loading = false;
    },
    async handleFileUpload(questionNo) {
      // this.data[questionNo] = this.$refs[questionNo].files[0];
      // console.log("file", this.data[questionNo]);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageUser");
    },

    async submit(pic) {
      console.log(this.file, "f");
      if (this.$refs.createForm.validate(true)) {
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        // this.dataNoPassword = {
        //   username: this.username,
        //   full_name: this.full_name,
        //   email: this.email,
        //   user_type: this.user_type,
        // };
        // this.dataChangePassword = {
        //   username: this.username,
        //   full_name: this.full_name,
        //   email: this.email,
        //   user_type: this.user_type,
        // };
        const data = {};
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/users`,
          data,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างบัญชีสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageUser");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>
